<template>
    <el-steps :active="active" simple>
        <el-step title="基本信息"><span slot="icon">1</span></el-step>
        <el-step title="场次设置"><span slot="icon">2</span></el-step>
        <el-step title="演出确认"><span slot="icon">3</span></el-step>
    </el-steps>
</template>

<script>
export default {
    props: {
        active: {
            type: Number,
            default: 1,
        },
    },
};
</script>

<style lang="scss" scoped>
.el-steps{
    margin-bottom: 20px;
    background: #EBEEF5;
}
</style>