<template>
    <div class="page-group-box">
        <div class="page-group-title">联系人信息</div>
        <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :xl="8">
                <el-form-item prop="activity.contactsName" :rules="[{required: true, message: '请填写联系人姓名'}]">
                    <span slot="label">联系人姓名:</span>
                    <el-input v-model="formData.contactsName"  :maxlength="40" placeholder="请填写联系人姓名" size="medium"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :xl="8">
                <el-form-item prop="activity.contactsTel" :rules="[{required: true, message: '请填写联系人手机号'},{ validator: $utils.validatePhone }]">
                    <span slot="label">联系手机: <small>填写联系手机号，可以接收演出审核通知短信</small></span>
                    <el-input v-model="formData.contactsTel" placeholder="请填写联系人手机号" size="medium"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :xl="8">
                <el-form-item prop="activity.contactsMail" :rules="[{required: true, message: '请填写联系人邮箱'},{ type: 'email', message: '请输入正确的邮箱地址' }]">
                    <span slot="label">联系邮箱: </span>
                    <el-input v-model="formData.contactsMail" :maxlength="100" placeholder="请填写联系人邮箱" size="medium"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props:{
       formData: {
            type: Object,
            default: null
        }, 
    },
}
</script>