<template>
    <div>
        <div class="page-group-box">
            <div class="page-group-title">演出基本信息</div>

            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.poster" label="演出海报:" :rules="[{required: true, message: '请上传演出海报'}]">
                        <image-upload 
                            :url.sync="formData.poster" 
                            :urlFull.sync="formData.poster" 
                            :original.sync="formData.posterOriginal"
                            :crop="true"
                            :min-width="1200"
                            :min-height="1600"
                            ref="posterUpload"
                            class="poster-upload"
                        >
                            <span slot="size">建议宽高比3:4,不小于1200*1600px</span>
                        </image-upload>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.title" :rules="[{required: true, message: '请输入演出名称'}]">
                        <span slot="label">演出名称: <small>当演出名称没有日期、城市、场地信息时无需补充，系统会自动生成</small></span>
                        <el-input v-model="formData.title" placeholder="请输入演出名称" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                    <el-form-item prop="activity.showTypeTagId" label="活动类型:" :rules="[{required: true, message: '请选择活动类型'}]">
                        <el-cascader v-model="formData.showTypeTagId" :options="showTypeTagList" :props="{value:'id',label:'name',children:'tags', emitPath:false}" placeholder="请选择活动类型" size="medium" style="width:100%"></el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row :gutter="24">
                <!-- <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.siteTypeTagId" label="场地类型:" :rules="[{required: true, message: '请选择场地类型'}]">
                        <el-cascader v-model="formData.siteTypeTagId" :options="siteTypeTagList" :props="{value:'id',label:'name',children:'tags', emitPath:false}" placeholder="请选择场地类型" size="medium" style="width:100%"></el-cascader>
                    </el-form-item>
                </el-col> -->
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.sponsorsIds">
                        <span slot="label">演出主办: <small>搜索场地、厂牌、艺人</small></span>
                        <el-select 
                            v-model="formData.sponsors" 
                            filterable 
                            remote
                            multiple
                            placeholder="请输入演出主办"
                            :remote-method="searchHost"
                            :loading="userLoading"
                            size="medium"
                            @change="selectSponsors"
                            value-key="id">
                            <el-option
                                v-for="item in hostList"
                                :key="item.id"
                                :label="`[${$userType.get(item.userType)}]${item.name}`"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.sellIdentity">
                        <span slot="label">售票模式: <small>选择秀动独家，可获得秀动官方向网易云音乐、大麦等平台的演出推荐</small></span>
                        <el-radio-group v-model="formData.sellIdentity" size="medium">
                            <el-radio :label="1" border>秀动独家</el-radio>
                            <el-radio :label="2" border>多平台售票</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <!-- <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.approvalsUserNum" :rules="[{required: formData.approvalsSn ? true : false, message: '请填写报批人数'}]" :show-message="formData.approvalsSn ? true : false">
                        <span slot="label">报批人数: </span>
                        <el-input-number style="width:100%" v-model="formData.approvalsUserNum" placeholder="请填写报批人数" size="medium" :min="0" :max="999999" :precision="0" :controls="false"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="activity.sponsorsIds">
                        <span slot="label">演出主办: <small>搜索场地、厂牌、艺人</small></span>
                        <el-select 
                            v-model="formData.sponsors" 
                            filterable 
                            remote
                            multiple
                            placeholder="请输入演出主办"
                            :remote-method="searchHost"
                            :loading="userLoading"
                            size="medium"
                            @change="selectSponsors"
                            value-key="id">
                            <el-option
                                v-for="item in hostList"
                                :key="item.id"
                                :label="`[${$userType.get(item.userType)}]${item.name}`"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.siteId" :rules='[{required: customSiteJson ? false : true, message: "请填写演出场地", trigger:"blur"}]'>
                        <span slot="label">
                            演出场地: 
                            <small>
                                如果没有搜索到需要的场地，可以
                                <el-button type="text" size="small" @click="$refs.customSite.show()">点击创建</el-button>
                            </small>
                        </span>
                        <el-select 
                            v-model="formData.siteVO" 
                            filterable 
                            remote
                            placeholder="请输入演出场地"
                            :remote-method="searchSite"
                            :loading="userLoading"
                            size="medium"
                            @change="selectSite"
                            value-key="key">
                            <el-option
                                v-for="(item,index) in siteList"
                                :key="index"
                                :label="item.name"
                                :value="item">
                            </el-option>
                        </el-select>
                        <div>地址: {{formData.siteVO && formData.siteVO.address ? formData.siteVO.address : customSiteJson && customSiteJson.address}}</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item prop="activity.serviceTemplateId">
                <p slot="label">购票须知:<span class="color-danger" v-if="formData.id">（红色模板为当前演出专用模板，其模板内容只在当前演出下展示）</span></p>
                <el-checkbox-group v-model="formData.serviceTemplateId" size="medium">
                	<el-table :data="serviceTemplateList" border style="width: 100%" size="small" class="templateTable MB20 ">
                		<el-table-column label="一级分类" width="100px" prop="label">
                			
                		</el-table-column>
                
                		<el-table-column label="二级分类" width="100px">
                			
                			<template slot-scope="{row} = scope">
                				<ul>
                					<li v-for="item in row.children" :key="item.value">{{item.label}} {{item.id}}</li>
                				</ul>
                				<!-- <span v-for="item in row.children" :key="item.value" class="MR20">
                					{{item.label}}
                				</span> -->
                			</template>
                		</el-table-column>
                		<el-table-column label="模板">
                			<template slot-scope="{row} = scope">
                				<ul class="secondUl">
                					<li v-for="(item,index) in row.children" :key="index" class="textLeft">
										<!-- <el-scrollbar style="whiteSpace:nowrap; width: 100%; height: 40px;"> -->
											<span v-for="item2 in item.children" :key="item2.id" class="MR20">
												<el-checkbox :label="item2.id.toString()">
													<span
														:class="{'color-danger': item2.bindIds}">{{item2.title}}[{{item2.id}}]</span>
												</el-checkbox>
												<el-popover width="400">
													<div v-html="item2.chineseContent"></div>
													<el-link slot="reference" type="info" :underline="false"
														style="vertical-align: baseline;"><span
															class="el-icon-question"></span></el-link>
												</el-popover><!-- 
											
												<el-button type="text" size="small" icon="el-icon-edit"
													v-if="item2.bindIds"
													@click="$refs.ActivitySyncTemplate.show(item2.id,item2.bindIds)">
												</el-button> -->
											</span>
										<!-- </el-scrollbar> -->
                					</li>
                					
                				</ul>
                
                			</template>
                		</el-table-column>
                	</el-table>
                </el-checkbox-group>             
            </el-form-item>
            <custom-site ref="customSite" @onUpdate="setCustomSite"></custom-site>
        </div>
        <div class="page-group-box MT10">
            <div class="page-group-title">批文信息</div>
            <el-form-item prop="activity.approvals" label="演出批文：">
                <!-- <span slot="label">演出批文: <small><span class="color-danger">无批文的演出可能会被隐藏，如果本演由其他公司代为报批，则需要填写对方公司名称且额外上传双方的约定协议（附公章）</span></small></span>    -->
                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <image-upload
                            :url.sync="formData.approvals1" 
                            :urlFull.sync="formData.approvals1" 
                            accept="image/png, image/jpeg, application/pdf"
							>
                            <span slot="type">支持格式为JPG、PNG、PDF;要求拍摄清晰，具有清晰可辨的编号和公章</span>
                            <span slot="size">不限制尺寸</span>
                            
                        </image-upload>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <image-upload
                            :url.sync="formData.approvals2" 
                            :urlFull.sync="formData.approvals2" 
                            accept="image/png, image/jpeg, application/pdf"
							
                        >
                            <span slot="type">支持格式为JPG、PNG、PDF;要求拍摄清晰，具有清晰可辨的编号和公章</span>
                            <span slot="size">不限制尺寸</span>
                            
                        </image-upload>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.approvalsSn" :rules="[{required: formData.approvals1 || formData.approvals2 ? true : false, message: '请输入批文编号'}]" :show-message="formData.approvals1 || formData.approvals2 ? true : false">
                        <span slot="label">批文编号: </span>
                        <el-input v-model="formData.approvalsSn" placeholder="请输入批文编号" size="medium" maxlength="40"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.approvalsPassTime" :rules="[{required: formData.approvals1 || formData.approvals2 ? true : false, message: '请输入报批通过日期'}]" :show-message="formData.approvals1 || formData.approvals2 ? true : false">
                        <span slot="label">报批通过日期: </span>
                        <el-date-picker v-model="formData.approvalsPassTime" type="datetime" placeholder="请输入报批通过日期" value-format="yyyy-MM-dd HH:mm:ss" size="medium"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :xl="8">
                    <el-form-item prop="activity.approvalsAgentCompany">
                        <span slot="label">主办方/承办方/协办方: </span>
                        <el-input v-model="formData.approvalsAgentCompany" placeholder="请输入主办方/承办方/协办方" size="medium" maxlength="100"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        
    </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload';
import CustomSite from './customSite';

import {fetchUser, templateALL} from '@/assets/js/commonApi';
import {fetchServiceTemplateType} from  '@/assets/js/commonApi'

export default {
    props:{
       formData: {
            type: Object,
            default: null
        },
        // approvals1: {
        //     type:String,
        //     default: null,
        // },
        // approvals2: {
        //     type:String,
        //     default: null,
        // }
    },
    components:{ImageUpload,CustomSite},
    data(){
        return{
            userLoading:false,
            //场地集合(临时)
            siteList:[],
            //主办方集合(临时)
            hostList:[...this.formData.sponsors],
            showTypeTagList: [],
            // siteTypeTagList: [],
            serviceTemplateType:[],
            serviceTemplate: null,
            serviceTemplateList: [],
			
        }
    },
    computed:{
        //自定义场地
        customSiteJson:{
            get(){
                return this.formData.customSite ? JSON.parse(this.formData.customSite) : null
            },
            set(v){
                this.$emit('update:formData',{...this.formData,customSite:JSON.stringify(v)})
            }
        },
        
    },
    async created(){
		
        this.serviceTemplateType = await fetchServiceTemplateType();
        this.getTemplate();
    },
    async mounted(){
        // await this.getSiteTypeTag();
        if((!this.formData.siteVO || !this.formData.siteVO.id) && this.customSiteJson){
            const _customSiteJson = {...this.customSiteJson,key: this.$utils.uuid()}
            this.siteList = [{..._customSiteJson}]
            this.$emit('update:formData',{...this.formData,siteVO:_customSiteJson})
            // this.formData.siteVO = {...customSiteJson};
        }else{
            this.siteList = this.formData.siteVO.id ? [{
                id: this.formData.siteVO.id || '',
                name:this.formData.siteVO.name || '',
                key: this.formData.siteVO.id || '',
            }] : [];
            this.$nextTick(() => {
                this.formData.siteVO = {...this.formData.siteVO,key: this.formData.siteVO.id || ''};
            })
        }
        this.getShowTypeTag();
        // if(this.formData.siteTypeTag && !this.siteTypeTagList.find(item => item.id === this.formData.siteTypeTag.id)){
        //     this.siteTypeTagList.push(this.formData.siteTypeTag);
        // }
        
    },
    methods:{
        //获取服务模板
        getTemplate(){
            templateALL({type: 1,activityId:this.formData.id}).then(res => {
                const{state, msg, result} = res.data;
                if(state && result){
                    this.serviceTemplate = result
                    var level1List = [{
                    		value: 1,
                    		label: '服务说明'
                    	}, {
                    		value: 2,
                    		label: '购票须知'
                    	}, {
                    		value: 3,
                    		label: '观演须知'
                    	}];
                    var newLevel = [];
                    	for (let key1 in this.serviceTemplate) {
                    		//console.log("key1",key1);
                    		var level1 = JSON.parse(JSON.stringify(level1List.find(v => v.value == key1)));
                    		level1.children = []
                    		for (let key2 in this.serviceTemplate[key1]) {
                    			var level2 = JSON.parse(JSON.stringify(this.serviceTemplateType.find(v => v.value == key2)));
                    			
                    			level2.children = this.serviceTemplate[key1][key2]
                    			level1.children.push(level2) 
                    			
                    
                    		}
                    		newLevel.push(level1)
                    	}
                    	console.log(newLevel)
                    	this.serviceTemplateList = newLevel
                }else{
                    this.$message.error(msg && "获取购票须知失败");
                }
            })
        },
        //获取场地类型
        // getSiteTypeTag(){
        //     this.$request({
        //         url: "/style/siteType/all",
        //         method: "post",
        //         params: {isAll: 0}
        //     }).then(res => {
        //         const { state, result } = res.data;
        //         if(state == 1){
        //             this.siteTypeTagList = [...result]
        //         }
        //     }) 
        // },
        //获取活动类型
        getShowTypeTag(){
           this.$request({
                url: "/style/showType/all",
                method: "post",
                params: {id: this.formData.id}
            }).then(res => {
                const { state, result } = res.data;
                if(state == 1){
                    this.showTypeTagList = result || []
                }
            }) 
        },
        // 搜索场地
        searchSite(query){
            this.searchUser(query,3,'siteList');
        },
        // 主办方搜索
        searchHost(query){
            this.searchUser(query,-1,'hostList');
        },
        // 搜索用户
        async searchUser(query,userType,obj){
            if(query !== ""){
                this.userLoading = true;
                const res = await fetchUser({
                    queryValue:query,
                    userType:userType,
                }).finally(() => {
                    this.userLoading = false;
                });
                
                const { state, result } = res.data;
                
                if (state == 1 && result) {
                    const _result = [...result];
                    _result.map(v => {
                        v.key = v.id
                    })
                    this[obj] = _result;
                    // console.log(this[obj])
                }
            }
        },

        //自定义场地
        setCustomSite(customData){
            const _customData = {
                ...customData,
                key: this.$utils.uuid()
            }
            
            this.siteList = [{
                    ..._customData
                }]
            // this.$emit('update:formData',{...this.formData,siteVO:{..._customData}})
            this.$nextTick(() => {
                this.formData.siteVO = {..._customData};
                
            })
            
            this.formData.siteId = "";
            this.customSiteJson = customData;
        },

        //选择场地
        selectSite(e){
            console.log(e.id)
            this.formData.siteId = e.id;
            // if(e.id) this.customSiteJson = null;
        },
        //选择主办
        selectSponsors(e){
            this.formData.sponsorsIds = e.map( v => v.id)
        }
    }
}
</script>

<style lang="scss" scoped>
.poster-upload{
    ::v-deep .image{
        width: 150px;
    }
}

.serviceTemplateList{
    display: flex;
    flex-wrap: wrap;
    > span{
        margin: 5px 20px 5px 0;
    }
}
.secondUl{		
	> li{
		// height: 40px;
		line-height: 40px;
		text-align: center;
		border-bottom: 1px solid #eee;
		&.textLeft{
			text-align:left;
			// padding-left: 20px;
		}
		&:last-child{
			border-bottom: none;
		}
        span{
            white-space: nowrap;
        }
	}
}
// ::v-deep .templateTable .cell{
// 	padding:0;
// 	margin:-10px 0;
// 	text-align: center;
// }
// ::v-deep ::-webkit-scrollbar-track{
// 	background: #eee;
// }
// ::v-deep ::-webkit-scrollbar {
// 	height: 1px;
// 	width: 1px;
// }
// ::v-deep ::-webkit-scrollbar-thumb{
// 	background: #1fbb7d;
// 	height: 1px;
// }
</style>