<template>
    <el-drawer title="创建场地" :visible.sync="drawerShow" :wrapperClosable="false" :destroy-on-close="true" size="auto"
        @close="close">
        <div class="drawer-content">
            <el-form :model="formDatas" :rules="rules" ref="formDatas" label-position="top">
                <el-form-item prop="name" label="场地名称:" ref="name">
                    <el-input v-model="formDatas.name" placeholder="请填写场地名称" :maxlength="40" size="medium"></el-input>
                </el-form-item>
                <el-form-item prop="siteType" label="场地类型:">
                    <el-select v-model="formDatas.siteType" placeholder="请选择场地类型" size="medium">
                        <el-option v-for="item in siteTypeList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="cityCode" label="省份城市:" ref="cityCode">
                    <el-cascader v-model="formDatas.cityCode" :options="cityList"
                        :props="{ label: 'name', children: 'cityList', value: 'code', emitPath: false }" size="medium">
                    </el-cascader>
                </el-form-item>

                <el-form-item prop="address" label="详细地址:" ref="address">
                    <el-input v-model="formDatas.address" placeholder="请填写场地详细地址" :maxlength="100" type="textarea"
                        autosize size="medium"></el-input>
                </el-form-item>

                <el-form-item prop="telephone" label="咨询电话:" ref="telephone">
                    <el-input v-model="formDatas.telephone" placeholder="请填写场地咨询电话" :maxlength="20"
                        size="medium"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { fetchAllCity, fetchSiteTagList } from '@/assets/js/commonApi';
const defaultFormData = {
    name: '',
    cityCode: 10,
    address: '',
    telephone: '',
    siteType: ''
}
export default {
    data() {
        return {
            drawerShow: false,
            btnLoading: false,
            formDatas: {
                ...defaultFormData,
            },
            rules: {
                name: [{ required: true, message: "请填写场地名称" }],
                cityCode: [{ required: true, message: "请选择所在城市" }],
                address: [{ required: true, message: "请填写场地详细地址" }],
                siteType: [{ required: true, message: '请选择场地类型' }],
            },
            cityList: [],
            siteTypeList: [],
        }
    },
    created() {
        this.getCity();
        this.getSiteType();
    },
    methods: {
        close() {
            this.$refs["name"].resetField();
            this.$refs["address"].resetField();
            this.$refs["telephone"].resetField();

            this.drawerShow = false;
        },
        show() {
            this.drawerShow = true;
        },
        //表单提交
        submitForm() {
            const _this = this;
            _this.$refs["formDatas"].validate((valid) => {
                if (valid) {
                    _this.$emit("onUpdate", { ..._this.formDatas });
                    _this.close();
                } else {
                    return false;
                }
            });
        },
        async getCity() {
            const res = await fetchAllCity({}).catch(() => { });
            const { state, result } = res.data;
            if (state == 1) {
                this.cityList = result;
                // this.cityList.map( item => {
                //     item.name = item.provinceName
                // });
            }
        },
        //获取场地类型
        async getSiteType() {
            const res = await fetchSiteTagList({}).catch(() => { });
            const { state, result } = res.data;
            if (state == 1) {
                this.siteTypeList = result;
            }
        },
    }
}
</script>
