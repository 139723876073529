<template>
     <div class="page-group-box">
        <div class="page-group-title">演出简介</div>
        <el-row :gutter="24">
            <el-col :xs="24" :xl="16">
                <el-form-item prop="activity.document" :rules="[{required: true, message: '请输入演出简介'}]" :show-message="formData.document ? false : true">
                    <tinymce v-model="formData.document" :height="500" :toolbar="toolbar" :menubar="false" class="MT20"></tinymce>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'; 
export default {
    components:{
        Tinymce,
    },
    props:{
       formData: {
            type: Object,
            default: null
        }, 
    },
    data(){
        return{
            toolbar:['formatselect fontsizeselect | forecolor bold strikethrough | alignleft aligncenter alignright alignjustify | image media | preview fullscreen'],
        }
    }
}
</script>