<template>
    <div>
        
        <div class="page-top-box">
            <!-- <el-page-header @back="goBack" :content="$route.meta.title"></el-page-header> -->
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <step-status :active="1"></step-status>
        
        <el-form
            :model="formData"
            ref="formData"
            label-position="top"
            v-loading="dataLoading"
        >
            <template v-if="reFresh">
                <activity-base-info :formData.sync="formData.activity" ></activity-base-info>
                <activity-contacts :formData.sync="formData.activity" class="MT10"></activity-contacts>
                <activity-document :formData.sync="formData.activity" class="MT10"></activity-document>
            </template>
        </el-form>

        <div class="page-group-box MT10">
            <el-button @click="goBack">返回</el-button>
            <el-button
                :disabled="claimInfo && claimInfo.status == 2 ? false : true"
                type="primary"
                @click="submitForm"
                :loading="btnLoading"
                >下一步</el-button
            >
        </div>
        
        <notice v-if="showNotice"></notice>
        
    </div>
</template>

<script>
import StepStatus from "./components/step-status";
import Notice from "./components/notice";
import ActivityBaseInfo from './components/activity-baseInfo';
import ActivityContacts from './components/activity-contacts';
import ActivityDocument from './components/activity-document';

export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components: {
        Notice,
        StepStatus,
        ActivityBaseInfo,
        ActivityContacts,
        ActivityDocument
    },
    data() {
        return {
            redirect: this.$route.query.redirect || '/manage/activity/draft',
            userId: this.$store.state.userInfo.id,
            formData:{
                activity:{
                    poster:'',
                    posterOriginal:'',
                    approvals:'',
                    // type:1,
                    title:'',
                    siteInfo:null,
                    siteId:'',
                    sponsors:[],
                    sponsorsIds:[],
                    contactsName:'',
                    contactsTel:'',
                    contactsMail:'',
                    sellIdentity:1,
                    customSite:'',
                    document:'',
                    siteVO:{id:'',name:''},
                    approvalsSn:'',
                    showTypeTagId:'',
                    approvalsPassTime:'',
                    siteTypeTagId: '',
                    serviceTemplateId: [],
                    approvalsUserNum: 0,
					approvals1:'',
					approvals2:'',
					
                }
            },
            dataLoading: false,
            btnLoading: false,
            reFresh:false,
            showNotice: false,
            claimInfo: null,
			
			
        };
    },
    created() {
        this.getClaimInfo();
        if (this.id) {
            this.getDataDetail();
        }else{
            this.reFresh = true;
            this.getAccountInfo();
        }
    },
    watch:{
        'formData.activity.poster'(v){
            if(v){
                this.$refs["formData"].clearValidate('activity.poster');
            }
        },
        'formData.activity.siteId'(v){
            if(v){
                this.$refs["formData"].clearValidate('activity.siteId');
            }
        },
        'formData.activity.customSite'(v){
            if(v){
                this.$refs["formData"].clearValidate('activity.siteId');
            }
        }
    },
    methods:{
        getAccountInfo(){
            this.$request({
                url: "/userInfo/getAccountInfo",
                params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.formData.activity.contactsName = this.formData.activity.contactsName || result.contactName;
                        this.formData.activity.contactsTel = this.formData.activity.contactsTel || result.telephone;
                        this.formData.activity.contactsMail = this.formData.activity.contactsMail || result.contactMail;
                    } else {
                        this.$message.error(msg || "获取用户信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取用户信息失败");
                })
        },
        //获取认证信息
        getClaimInfo(){
            this.$request({
                url: "/user/register/getClaimInfo",
                params: {userId:this.$store.state.userInfo.id},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.claimInfo = result;
                        const _msg = [
                            "您还未进行认证，无法使用演出发布功能，建议您尽快认证",
                            "当前认证信息正在等待审核，请耐心等待审核结果",
                            "您的认证信息已通过审核",
                            `您的认证信息未通过审核（原因：${result.remarks}），请修改后重新提交审核`,
                        ]

                        if( result.status != 2 ){
                            this.$confirm(result.status ? _msg[result.status] : _msg[0], '认证信息', {
                                confirmButtonText: result.status == 3 ? '立即修改' : result.status == 1 ? '查看认证' : '立即认证',
                                showCancelButton:false,
                                showClose:false,
                                customClass:'mpop',
                                closeOnClickModal: false,
                                closeOnPressEscape: false,
                                type:"warning"
                            }).then( () => {
                                this.$router.push({
                                    path:'/manage/workbench/auth'
                                })
                            }).catch(() => {});
                        }else{
                            this.showNotice = true;
                        }
                    } else {
                        this.$message.error(msg || "获取认证信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取认证信息失败");
                })
                .finally(() => {
                    // this.dataLoading = false;
                });
        },
        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/activity/getBaseInfoById",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData = {
                            activity:{
                                ...this.formData.activity,
                                ...result,
                                siteId: result.siteId || '',
                                sponsorsIds: result.sponsors.map(v => v.id) || [],
                                customSite: result.customSite || '',
                                serviceTemplateId: result.serviceTemplateId ? result.serviceTemplateId.split(',') : []
                            }
                        };
                        if( this.formData.activity.approvals ){
                        	var approvals = this.formData.activity.approvals.split(',')							
                        	if( approvals.length > 0 && approvals[0]){
                        		this.formData.activity.approvals1 = approvals[0]
                        	}
                        	if( approvals.length > 0 && approvals[1]){
                        		this.formData.activity.approvals2 = approvals[1]
                        	}
                        }
                        this.reFresh = true
                    } else {
                        self.$message.error(msg || "获取演出失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //表单提交
        submitForm() {
            let self = this;
			var approvals = ''
			if( this.formData.activity.approvals1 != '' && this.formData.activity.approvals2 != '' ){				
				approvals = this.formData.activity.approvals1 +","+this.formData.activity.approvals2
			}else if( this.formData.activity.approvals1 == '' ){
				approvals = this.formData.activity.approvals2
			}else if( this.formData.activity.approvals2 == '' ){
				approvals = this.formData.activity.approvals1
			}else{
				approvals = ''
			}
			this.formData.activity.approvals = approvals
			
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    
                    const _url = "/activity/publishBaseInfo";
                    this.btnLoading = true;

                    this.$request({
                        url: _url,
                        data: {
                            ...this.formData.activity,
                            serviceTemplateId: this.formData.activity.serviceTemplateId.toString()
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg, result } = res.data;
                            if (state == 1) {
                                //self.goBack()
                                this.$router.push({
                                    path: `/manage/activity/edit/step2/${result}`,
                                    query: {
                                        publishType: this.id ? 2 : 1,  //1、新建，2、编辑
                                    },
                                });
                            } else {
                                self.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            self.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    }
    
};
</script>
